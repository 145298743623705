import { styled as styling } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Color from "../../../../assets/colors";
import Box from "@mui/material/Box";

// Grid
export const TrendingContainer = styling(Grid)((props) => ({
  height: "auto",
  minWidth: "520px",
  maxWidth: "520px",
  flexWrap: "nowrap",
  minHeight: "58px",

  [props.theme.breakpoints.down("lg")]: {
    margin: "0px auto 42px auto",
  },

  [props.theme.breakpoints.down("tablet")]: {
    minWidth: "328px",
    maxWidth: "328px",
  },
}));

export const ChipContainer = styling(Grid)((props) => ({
  width: "100%",
  overflow: "hidden",

  [props.theme.breakpoints.down("tablet")]: {
    width: "100%",
    height: "100%",
    maxHeight: "60px",
  },
}));

export const BlankContainer = styling(Grid)((props) => ({
  width: "120px",
  [props.theme.breakpoints.down("tablet")]: {
    width: "40px",
  },
}));

export const TrendingTextBox = styling(Box)((props) => ({
  backgroundColor: "rgba(242, 175, 41, 0.14)",
  width: "auto",
  padding: "2px 6px 2px 6px",
  marginRight: "20px",
  border: `2px solid ${Color.chipGrey}`,
  boxShadow: "3px 3px 0px 0px rgba(0,0,0,1)",
  borderRadius: "4px",

  [props.theme.breakpoints.down("tablet")]: {
    transform: `rotate(-90deg) scale(0.7)`,
    marginTop: "15px",
    marginRight: "0px",
  },
}));

// Chip
export const ChipStyled = styling(Chip)((props) => ({
  color: props.theme.palette.text.disabled,
  border: `1px solid ${props.theme.palette.text.disabled}`,
  fontWeight: "bold",
  fontSize: "12px",
  padding: "0px 12px 0px 12px",

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "10px",
    padding: "0px 6px 0px 6px",
    height: "21px",
  },

  ":hover": {
    color: Color.black,
    border: `1px solid ${Color.black}`,
    backgroundColor: `${Color.white} !important`,
  },
}));

// Typography
export const TypographyStyled = styling(Typography)({});
