import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../../helpers/tracking_management";
import {
  getTrendingChip,
  updateJobSearchKeyword,
} from "../../../../redux/actions/job_action";
import { store } from "../../../../redux/stores/store";
import {
  BlankContainer,
  ChipContainer,
  ChipStyled,
  TrendingContainer,
  TrendingTextBox,
  TypographyStyled,
} from "./styles";

export default function HomepageTrending(props) {
  const trendingChip = useSelector((state) => state?.jobs?.trendingChip);

  useEffect(() => {
    dispatch(getTrendingChip());
  }, []);

  const router = useRouter();

  const dispatch = useDispatch();

  const redirectToJobs = (params) => {
    const keyword = params.keyword;

    // GTM tracking custom event for tracking clicks of trending chips
    sendTrackingEvent({
      event: "CE_click-search-job-trending-header",
      "search-term": keyword,
    });

    dispatch(updateJobSearchKeyword({ keyword: keyword ?? "" }));

    store.getState().jobs.jobListFilter["keyword"] = keyword;
    router.push("/jobs");
  };

  const screenMobile = useMediaQuery("(min-width:768px)");

  return Array.isArray(trendingChip) && trendingChip.length > 0 ? (
    <TrendingContainer container direction="row" alignItems="flex-start">
      <BlankContainer container justifyContent="center" alignItems="center">
        <TrendingTextBox>
          <TypographyStyled variant="body1_bold_L">Trending</TypographyStyled>
        </TrendingTextBox>
      </BlankContainer>

      <ChipContainer
        container
        rowGap={"10px"}
        columnGap={screenMobile ? "10px" : "4px"}
      >
        {trendingChip.slice(0, 5).map((chip) => {
          return (
            <ChipStyled
              key={chip?.attributes?.keyword}
              label={chip?.attributes?.keyword}
              clickable
              variant="outlined"
              size="small"
              onClick={() => redirectToJobs(chip?.attributes)}
            />
          );
        })}
      </ChipContainer>
    </TrendingContainer>
  ) : (
    <Fragment></Fragment>
  );
}
